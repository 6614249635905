<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="phone"
        name="phone"
        label="手机号"
        placeholder="请输入"
      />
      <van-field
        v-model="captcha"
        name="captcha"
        center
        clearable
        label="短信验证码"
        placeholder="请输入"
      >
        <template #button>
          <van-button size="small" type="primary" :disabled="btnCaptcha.disabled" @click="onSendCaptcha">{{ btnCaptcha.text }}</van-button>
        </template>
      </van-field>
      <van-field
        v-model="password"
        name="password"
        label="密码"
        placeholder="6-20位"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        确认
      </van-button>
    </div>
  </van-form>
  <rights />
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import Rights from '@/components/Rights.vue'
import { Toast } from 'vant'

const constBtnCaptcha = {
  disabled: false,
  text: '发送验证码',
  scope: 60
}

const constBtnSubmit = {
  loading: false
}

export default {
  components: {
    Rights
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const useRoute = inject('useRoute')
    const state = reactive({
      phone: '',
      captcha: '',
      password: '',
      wechatOpenid: Cookies.get('wechatOpenid'),
      btnCaptcha: Object.assign({}, constBtnCaptcha),
      btnSubmit: Object.assign({}, constBtnSubmit)
    })
    const countDown = () => {
      setTimeout(() => {
        state.btnCaptcha.scope = state.btnCaptcha.scope - 1
        if (state.btnCaptcha.scope > 0) {
          state.btnCaptcha.text = state.btnCaptcha.scope + '秒后可重发'
          countDown()
        } else {
          state.btnCaptcha = Object.assign({}, constBtnCaptcha)
        }
      }, 1000)
    }
    const onSendCaptcha = () => {
      state.btnCaptcha.disabled = true
      post('/util.sendCaptcha', {
        phone: state.phone
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
          countDown()
        } else {
          Toast(res.msg)
          state.btnCaptcha = Object.assign({}, constBtnCaptcha)
        }
      })
    }
    const onSubmit = (values) => {
      state.btnSubmit.loading = true
      post('/user.register', {
        phone: state.phone,
        captcha: state.captcha,
        password: state.password,
        wechatOpenid: state.wechatOpenid
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
          useRouter.push({
            name: 'Login',
            query: useRoute.query
          })
        } else {
          Toast(res.msg)
          state.btnSubmit.loading = false
        }
      })
    }
    const init = () => {
      if (Cookies.get('token')) {
        useRouter.go(-1)
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onSendCaptcha
    }
  }
}
</script>

<style>
body {
}
</style>
